import React, { Component } from 'react';

class PovProcess extends Component {

  render() {
    return (
      <div>
        <h1> Wire-frame </h1>
        <img src={require('./povWireframe.png')}  alt="..."/>
        <img src={require('./componentTree.png')}  alt="..."/>
      </div>
    );
  }

}

export default PovProcess;
